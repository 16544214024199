.root {
  border-top: solid 5px blue;
}

.group {
  display: flex;
  align-items: center;
  margin: 8px;
}

.separator {
  font-size: 150%;
  margin: 0px 10px;
}

.digits {
  display: flex;
  width: calc(100% * (1 / 2));
  flex-direction: column;
}

.digits > input,
.digits > select {
  background: transparent;
  width: 100%;
  font-size: 16px;
  text-align: center;
  padding: 16px 0px;
  border: transparent;
}

.digits > button {
  padding: 8px;
  margin: 10px 0px;
}

#period {
  margin-left: 16px;
}
