.root {
  display: flex;
  align-items: center;
  color: #a6a6a6;
}

.left {
  display: flex;
  align-items: center;
  flex: 1;
}

.left::after {
  background: #a6a6a6;
  height: 1px;
  content: '';
  flex: 1;
  margin-top: 1px;
  margin-left: 16px;
  margin-right: 16px;
}
