/* 
  Semantic styling for making a file input
  invisible but not breaking usability.
*/
.input-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.btn-outline-secondary {
  border: solid 1px #aaa;
}