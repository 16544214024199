button:focus,
button:focus-visible {
  outline: #97ba52 solid 2px !important;
  outline-offset: 1px;
}

.pintura-editor {
  --color-background: 255, 255, 255;
  --editor-modal-border-radius: 100;
  --color-primary: #006ab0;
  --color-primary-dark: #004c80;
  --color-primary-text: white;
}

.pintura-editor {
  --border-radius: 0.2em;
  --color-foreground: 0, 106, 176;
  --color-secondary: #fff;
}

.PinturaScrollable {
  padding: 3px;
}

.PinturaRoot > .PinturaNavMain [aria-selected='true'] button,
.PinturaTabList PinturaControlList [aria-selected='true'] button {
  backdrop-filter: none;
  background: rgb(var(--color-foreground));
  color: white;
  margin: 1px;
}

.PinturaUtilFooter
  .PinturaControlList
  [role='tab'][aria-selected='true']
  button {
  backdrop-filter: none;
  background: rgb(var(--color-foreground));
  color: white;
}
