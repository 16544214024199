:root {
  --size: 20px;
  --speed: 0.55s;
  --thickness: 4px;
  --color: #05bbed;
}

.error-msg {
  color: #ca2527;
  font-size: 85%;
}

.input-async {
  position: relative;
}

.input-async::after {
  position: absolute;
  top: 22px;
  right: 12px;
  content: '';
  display: inline-block;
  width: var(--size);
  height: var(--size);
  border: solid var(--thickness) var(--color);
  border-left-color: transparent;
  border-radius: 50%;
  animation-name: rotate-forever;
  animation-duration: var(--speed);
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.input-async-inline {
  position: relative;
}

.input-async-inline::after {
  position: absolute;
  top: 10px;
  right: 20px;
  content: '';
  display: inline-block;
  width: var(--size);
  height: var(--size);
  border: solid var(--thickness) var(--color);
  border-left-color: transparent;
  border-radius: 50%;
  animation-name: rotate-forever;
  animation-duration: var(--speed);
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rotate-forever {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
