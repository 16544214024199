.block {
  margin-bottom: 20px;
}

.name {
  font-size: 16px;
  font-weight: 800;
  padding: 5px 0px 4px 0px;
}

.preview-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.preview {
  width: 90px;
  height: 90px;
  overflow: hidden;
  position: relative;
  float: left;
  border-radius: 4px;
}

.preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.overlay {
  background-color: rgba(151, 186, 82, 0.5);
  padding-top: 12px;
  color: #fff;
  text-align: center;
  width: 120px;
  height: 71px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}

.inc-btn {
  float: right;
  color: #999;
}

.inc-btn--close:hover {
  color: red;
}

.info {
  font-size: 95%;
  padding-top: 6px;
  color: #999;
}

.progress-bar {
  margin-top: 4px;
  background: #ccc;
}

.progress-data {
  background: #97ba52;
  height: 8px;
}

/* Dev Digital Below */

.block {
  margin-bottom: 20px;
  padding-top: 20px;
  min-height: 71px;
  /* border-top: #c4c4c4 1px solid; */
}

.block:first-of-type {
  border-top: 0px;
}

.name {
  font-size: 18px;
  font-weight: 600;
  padding: 0px 0px 4px 0px;
  color: #444447;
}

.preview {
  width: 70px;
  height: 70px;
  overflow: hidden;
  position: relative;
  float: left;
  margin-right: 18px;
  border-radius: 4px;
}

.preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.overlay {
  background-color: rgba(151, 186, 82, 0.5);
  padding-top: 12px;
  color: #fff;
  text-align: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}

.description {
  margin-left: 86px;
}

/* .description textarea {
  width: 100%;
  height: 42px;
  border: none;
  box-sizing: border-box;
  border-top: solid 2px #ddd;
} */

.inc-btn {
  float: right;
  color: #999;
  display: flex;
  align-items: center;
  height: 50px;
}
.inc-btn--close {
  color: #999;
  margin-left: 15px;
}
.inc-btn--close:hover {
  color: #333;
}

.info {
  font-size: 95%;
  padding-top: 6px;
  color: #999;
}

.progress-bar {
  margin-top: 4px;
  background: #ccc;
}

.progress-data {
  background: #97ba52;
  height: 8px;
}
.addInfoBtn {
  color: #0068a8;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
}
.addInfoBtn svg {
  padding-right: 5px;
  box-sizing: border-box;
}

.fileImageBlock {
  position: relative;
}

.fileImageBlock:before {
  content: '';
  padding-top: calc(100% / 1 * 1);
  position: relative;
  width: 100%;
  display: block;
}
.fileImageBlock .responsive {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.fileImageBlock .responsive .img-cover {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.deleteIcon {
  cursor: pointer;
}
