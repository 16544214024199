/* apply a natural box layout model to all elements, but allowing components to change */
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

.note-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid 1px #c4c4c4;
  border-radius: 3px;
  padding: 8px;
}

.note-panel::focus {
  border: solid 2px blue;
}

.note-title {
  width: 100%;
  flex: 1;
  border: none;
  margin: 0;
  outline: none;
  font-weight: 600;
  padding: 8px;
}

.note-body {
  width: 100%;
  flex: 1;
  border: none;
  margin: 0;
  outline: none;
  font-weight: 300;
  padding: 8px;
}

.note-title,
.note-body {
  font-size: 16px;
}
