.form-header {
  margin-bottom: 15px;
}

.form-description {
  margin-top: 8px; 
  margin-bottom: 8px;
}


.form-header span {
  color: #006ab0;
  font-size: 120%;
}

.bottom-space {
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.btn-submit {
  background: #97ba52;
  border: solid 1px #90b14f;
  padding: 8px 40px;
}

.btn-submit:hover {
  background: #819e46;
  border: solid 1px #819e46;
}

.btn-cancel {
  margin-right: 15px;
  padding: 8px 40px;
  transition: all .30s ease-in-out;
}

.btn-cancel:hover {
  border-radius: 5px;
  background: #D50000;
  color: #fff;
  text-decoration: none;
}

.btn-pe {
  margin-top: 30px;
}

.btn-goback {
  color: #666;
  font-size: 95%;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
}

.btn-goback:hover {
  color: #006ab0;
}