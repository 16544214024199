.form-body {
  padding-top: 140px;
  padding-left: 20px;
  width: 100%;
}

.form-block {
  background: #fff;
  background-image: url(/src/client/images/bg-tree.jpg);
  background-size: 45%;
  background-repeat: no-repeat;
  background-position: right 400px;
  background-position-x: 280px;

  padding: 20px;
  height: 500px;
  margin-bottom: 20px;
}

.form-block h3 {
  padding: 0;
  margin: 20px 0;
}

.form-block hr {
  border-top: solid 1px #cecece;
  margin: 25px 0;
}

.accent-or {
  background: #fff;
  display: block;
  font-style: italic;
  color: #cecece;
  padding: 0 10px;
  position: absolute;
  top: -10px;
  left: 30%;
  text-align: center;
}

/* .form-block input {
  border: solid 1px #cecece;
  border-radius: 0;
} */

/* .form-block label {
  display: none;
} */

.form-block .btn {
  width: 100%;
}

.btn-signin {
  width: 100%;
  background: #97ba52;
  color: #fff !important;
  padding: 10px 20px;
  &:disabled:hover {
    color: #999 !important;
  }
  &:hover {
    background: color(#97ba52 blackness(+10%));
    color: #ffffff;
  }
}

.disabled {
  background: red;
}

.btn-register {
  background: #006ab0;
  color: #fff;
  padding: 10px 20px;
  width: 100%;
  &:hover {
    background: color(#006ab0 blackness(+10%));
    color: #ffffff;
  }
}

.error-msg {
  color: #ca2527;
  font-size: 85%;
}

@media (max-width: 800px) {
  .form-body {
    padding-left: 0px;
  }
}
