.body {
  background: #f5f7f7;
}

.form {
  margin-bottom: 100px;
}

.signup-member-page {
  padding-top: 100px;
}
.signup-member-form {
  background: #fff;
  padding: 20px;
  padding-top: 25px;
  border: solid 1px #cecece;
  margin-bottom: 50px;
}

.btn-signin {
  background: #97ba52;
  color: #fff !important;
  padding: 10px 20px;
  &:disabled:hover {
    color: #999 !important;
  }
  &:hover {
    background: color(#97ba52 blackness(+10%));
    color: #ffffff;
  }
}
