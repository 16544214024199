.rightDrawerPanel {
  width: 550px;
}
.mediaHolder {
  padding: 20px;
}
.searchHolder {
  border: 2px solid #aeaeae;
  border-radius: 4px;
  padding: 0 10px;
  margin: 0 10px 10px 10px;
}
.mediaGrid {
  width: 100%;
}
.mediaList {
  width: 100%;
}
.mediaBlocks {
  background: #eff1f3;
  padding: 10px;
  margin: 10px;
  width: calc(50% - 20px);
  min-height: 220px;
  display: inline-block;
  cursor: pointer;
}
.mediaList .mediaBlocks {
  background: transparent;
  width: calc(100% - 20px);
  min-height: 120px;
  padding: 0px;
}
.mediaDetails {
  width: auto;
}
.mediaList .mediaDetails {
  padding-left: 15px;
  float: left;
  width: calc(100% - 135px);
}
.mediaBox {
  position: relative;
  width: 100%;
  height: 180px;
  display: inline-block;
}
.mediaList .mediaBox {
  width: 120px;
  height: 120px;
  float: left;
  border-radius: 6px;
  overflow: hidden;
}
.mediaBox:before {
  content: '';
  padding-top: calc(100% / 1 * 1);
  position: relative;
  width: 100%;
  display: block;
}
.mediaBox .responsive {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
}
.mediaBox .responsive .img-cover {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.mediaName {
  font-size: 16px;
  font-weight: 600;
  color: #444447;
  padding: 5px 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.mediaList .mediaName {
  white-space: inherit;
  text-overflow: inherit;
  overflow: inherit;
}
.mediaDate {
  font-size: 14px;
  font-weight: normal;
  color: #444447;
}
.mediaList .mediaDate {
  font-weight: 600;
}
.mediaDescription {
  font-size: 16px;
  font-weight: 500;
  color: #737373;
  line-height: 24px;
  padding-top: 5px;
  display: none;
}
.mediaList .mediaDescription {
  display: block;
}
.searchInput {
  width: calc(100% - 48px);
  height: 50px;
}
.listIcon {
  position: absolute;
  width: 30px !important;
  height: 30px !important;
  left: 30px;
  top: 7px;
  z-index: 1;
  cursor: pointer;
  color: #dadada;
}
.listIcon.active {
  color: #444447;
}
.gridIcon {
  width: 22px;
  height: 22px;
  left: 65px;
  position: absolute;
  top: 10px;
  z-index: 1;
  cursor: pointer;
  color: #dadada;
}
.gridIcon.active {
  color: #444447;
}
.overviewBackBtn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  color: #737373;
  position: absolute;
  z-index: 1;
  top: 12px;
  left: 25px;
  font-weight: bold;
  cursor: pointer;
}
.overviewBackBtn svg {
  color: #737373;
  margin-right: 10px;
}
.deleteBtn {
  background: #ed5158 !important;
  color: #fff !important;
  margin-left: 15px !important;
}
.deleteDialogHolder {
  text-align: center;
}
.deleteDialogHolder .dialogTitle {
  padding: 30px 25px 10px;
}
.deleteDialogHolder .dialogTxt {
  color: #000 !important;
}
.deleteDialogHolder .dialogBtns {
  justify-content: center;
  margin: 25px 0;
}

/* Media Edit */
.iconViewer {
  border-radius: 8px;
  width: calc(100% - 15px);
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}
.mBottom {
  margin-bottom: 15px !important;
}
.mediaDetailsBlock {
  display: inline-block;
  color: #737373;
}
.mediaDetailsBlock h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin: 0 0 10px 0;
  color: #444447;
}
.mediaImageBlock {
  position: relative;
}
.mediaImageBlock:before {
  content: '';
  padding-top: calc(100% / 16 * 9);
  position: relative;
  width: 100%;
  display: block;
}
.mediaImageBlock .responsive {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.mediaImageBlock .responsive .img-cover {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.dialogHolder {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
}
.dialogContentHolder {
  padding: 25px;
  height: calc(100% - 50px);
  box-sizing: border-box;
}
.dialogContentHolder img {
  max-width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-size: inherit !important;
}
.dialogContentHolder iframe {
  height: calc(100vh - 104px);
}
.dialogContentHolder iframe video {
  width: 100%;
}
.iconsStyle {
  margin: 0 10px;
  color: #757575 !important;
  cursor: pointer;
}

.iconViewer {
  border-radius: 8px;
  background: #f0f0f0;
  width: calc(100% - 15px);
  height: 210px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  cursor: pointer;
}
.mBottom {
  margin-bottom: 20px !important;
}
.mediaDetailsBlock {
  display: inline-block;
  color: #737373;
}
.mediaDetailsBlock h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin: 0 0 10px 0;
  color: #444447;
  display: flex;
}
.mediaDetailsBlock span {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  margin: 0 0 20px 0;
  display: inline-block;
}
.mediaDetailsBlock p {
  font-size: 16px;
  line-height: 18px;
  margin: 0 0 15px 0;
}
.mediaImageBlock {
  position: relative;
  cursor: pointer;
}
.mediaImageBlock:before {
  content: '';
  padding-top: calc(100% / 16 * 9);
  position: relative;
  width: 100%;
  display: block;
}
.mediaImageBlock .responsive {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.mediaImageBlock .responsive .img-cover {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.videoBlock {
  cursor: pointer;
  display: flex;
  width: 100%;
  min-height: 200px;

  align-items: center;
}
.videoThumb {
  object-fit: cover;
}
.addCircleIcon {
  position: absolute;
  top: 10px;
  right: 30px;
  color: #0068a8;
  cursor: pointer;
}

.downloadIcon {
  color: #0068a8;
  position: relative;
  margin: 0px 5px 0 0;
  cursor: pointer;
}

h3.editMode {
  margin-top: 5% !important;
  margin-bottom: 5% !important;
}

.customeinheritcolor {
  color: red;
}
.customPagination {
  margin-bottom: 30px;
  position: relative;
  text-align: center;
}
.customPagination button {
  min-width: 36px;
  background: #f0f0f0;
  border-radius: 0;
}
.customPagination button:first-child {
  background: #f0f0f0;
  border-radius: 50px;
  position: absolute;
  left: 0;
}
.customPagination button:last-child {
  background: #f0f0f0;
  border-radius: 50px;
  position: absolute;
  right: 0;
}
.customPagination button:nth-child(2) {
  border-radius: 50px 0 0 50px;
}
.customPagination button:nth-last-child(2) {
  border-radius: 0 50px 50px 0;
}
