/*$headerBgColor: #006ab0;*/

header {
  width: 100%;
  background-color: #006ab0;
  padding: 8px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 900;
}

/* header img {
 height: 40px; 
} */








