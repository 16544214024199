.sidebar {
  position: relative;
  background-position: center 95%;
  background-repeat: no-repeat;
  background-size: 130px;
}

.sidebar .text-blue {
  font-size: 110%;
  text-align: center;
  color: #006ab0;
}

.sidebar .text-green {
  color: #97ba52;
}

.avatar {
  margin: 0 auto;
}

.user-name {
  margin-bottom: 25px;
}
