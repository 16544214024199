.rbc-event-label {
  display: none;
}

.form-control {
  height: 40px;
}

.btn {
  padding: 7px 12px;
}

.Select-menu-outer {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top-color: #e6e6e6;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 700 !important;
  -webkit-overflow-scrolling: touch;
}

.bottom-space {
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.btn-submit {
  background: #97ba52;
  border: solid 1px #90b14f;
  padding: 8px 40px;
}

.btn-submit:hover {
  background: #819e46;
  border: solid 1px #819e46;
}

.btn-cancel {
  margin-right: 15px;
  padding: 8px 40px;
  transition: all 0.3s ease-in-out;
}

.btn-cancel:hover {
  border-radius: 5px;
  background: #d50000;
  color: #fff;
  text-decoration: none;
}

.StripeElement {
  padding: 15px 14px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
}

::-webkit-calendar-picker-indicator {
  margin-left: 2px;
}
