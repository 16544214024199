li {
  list-style: none;
}

.btn,
.btn:hover {
  color: #fff;
  outline: none;
  box-shadow: none;
}

.btn-red {
  background-color: #ca2527 !important;
  /* todo: figure out how to do this without !important */
}

.dashboard-header {
  padding: 0px;
  padding-right: 15px;
}

.dashboard-header-left {
  padding: 8px;
}

.dashboard-header-right {
  height: 100%;
}

ul.wide-screen-header-nav {
  padding-left: 0;
  height: 100%;
  margin: 0;
  box-sizing: border-box;
}

ul.wide-screen-header-nav li {
  float: left;
  height: 100%;
}

ul.wide-screen-header-nav li a {
  color: #eee;
  height: 100%;
  display: block;
  margin: 0;
  padding: 16px 10px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

ul.wide-screen-header-nav li a.active {
  background-color: #005f9e;
  color: #fff;
}

ul.wide-screen-header-nav li a:hover {
  color: #fff;
  background-color: #005f9e;
}

ul.wide-screen-header-nav .btn {
  margin-left: 25px;
  padding: 5px;
  margin-top: 10px;
  text-transform: inherit;
  font-weight: normal;
  height: auto;
}

.DashboardHeader {
  background-color: #006ab0;
  border-bottom: solid 1px #0081d9;
}

.DashboardHeader img {
  width: 240px;
  margin-left: -7px;
  margin-top: -7px;
}

.DashboardHeader ul > li.visible-nav > a,
.DashboardHeader ul > li.hidden-nav > a {
  color: white;
  font-weight: 500;
}

.DashboardHeader ul > li.visible-nav > a:hover,
.DashboardHeader ul > li.hidden-nav > a:hover,
.DashboardHeader ul > li.visible-nav > a:focus,
.DashboardHeader ul > li.hidden-nav > a:focus {
  background-color: #005f9e;
  color: white;
}

.DashboardHeader li.hidden-nav {
  display: none;
}

.navbar-toggle {
  border: none;
}

.navbar-toggle > span {
  background-color: white !important;
}

.navbar-toggle:hover,
.navbar-toggle:focus {
  background-color: #004c80 !important;
}

.visible-nav > a {
  line-height: 25px !important;
}

@media (max-width: 767px) {
  .DashboardHeader li.hidden-nav {
    display: block;
  }
}

@media (max-width: 500px) {
  .DashboardHeader img {
    width: 240px;
  }
}
