.calendar {
  font-size: 85%;
  width: 320px;
  border: solid 1px #ccc;
  border-top: solid 5px #006ab0;
  box-shadow: 5px 5px 5px 1px #eee;
  box-sizing: border-box;
  position: absolute;
  top: 24px;
  z-index: 4000;
}

.header {
  display: flex;
  align-items: center;
  padding: 16px 0px;
  padding-right: 0px;
}

.header > div {
  flex-grow: 1;
  font-size: 20px;
  cursor: pointer;
}

.header > div:nth-child(1) {
  padding-left: 16px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  padding-right: 12px;
}

.body {
  padding: 6px;
}

.row,
.labels {
  display: flex;
}

.labels {
  border-bottom: solid 1px #eee;
  margin-bottom: 8px;
  margin: 0 8px;
}

.labels .label {
  flex-grow: 1;
  padding: 8px 0px;
  text-transform: uppercase;
  text-align: center;
  color: #666;
}

.row .day {
  flex-grow: 1;
  width: calc(100% * (1 / 7));
  text-align: center;
  padding: 10px;
  margin: 3px;
  cursor: pointer;
}

.day > span {
  color: transparent;
}

.row .day:hover:not(.current):not(.selected):not(.blank):not(.disabled) {
  transition: color 0.5s, border 0.5s;
  color: #006ab0;
  border: solid 1px #006ab0;
  padding: 9px;
}

.row .blank {
  color: #ccc;
}

.row .disabled {
  background: #eee;
  color: #ccc;
  cursor: not-allowed !important;
}

.current,
.row .day.current {
  padding: 9px;
  border: 1px solid #006ab0;
  border-radius: 3px;
  color: #006ab0;
  font-weight: bold;
}

.selected,
.row .day.selected {
  background: #006ab0;
  padding: 9px;
  border: 1px solid #006ab0;
  border-radius: 3px;
  color: #fff;
  font-weight: bold;
  cursor: default;
}

.row .blank:hover:not(.disabled) {
  border: solid 1px #bbb;
  padding: 9px;
}

.month-panel {
  width: inherit;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  text-transform: uppercase;
  padding: 8px;
}

.year-panel {
  width: inherit;
  height: 280px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow: scroll;
  padding: 8px;
}

.month-panel > div,
.year-panel > div {
  width: calc(100% * (1 / 3) - 16px);
  margin: 8px 8px;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  box-sizing: border-box;
}

.year-panel > div:hover:not(.current),
.month-panel > div:hover:not(.current) {
  background: #eee;
  border-radius: 3px;
  cursor: pointer;
}
