@media screen and (max-width: 767px) {
  .tabs-wrap {
    /* display: none; */
  }
}

.disabledLink {
  color: rgb(51, 51, 51);
  background-color: #fff;
}

.disabled {
  pointer-events: none;
}

.tabs-wrap {
  height: 48px;
  margin-bottom: 20px;
  font-weight: bold;
  background-color: #f2f2f2;
  border-bottom: solid 1px #ddd;
  padding: 0;
}

.tabs-wrap li.tab .fa-angle-double-right,
.dashboard-main .tabs-wrap li.tab .fa-angle-double-down {
  color: #97ba52;
  float: right;
  margin-top: -27px;
  margin-right: 5px;
  font-weight: bold;
  display: none;
}

.tabs-wrap .tab-two .fa-plus-circle {
  font-size: 14px;
  margin-right: 3px;
}

.tabs-wrap .tab-two a:first-child {
  position: relative;
  top: 2px;
}

.tabs-wrap .tab-two a.pull-right {
  font-size: 14px;
}

.tabs-wrap li.tab {
  float: left;
  height: 100%;
  border-right: 1px solid #ddd;
}

.tabs-wrap li.tab a {
  padding: 15px;
  display: block;
  color: rgb(148, 148, 148);
  font-weight: normal;
  text-decoration: none;
}

.tabs-wrap li.tab a:hover {
  background: #eceff0;
  color: #4c585f;
  text-shadow: 0 0 5px #fff;
  border-bottom: solid 1px #fff;
}

.tabs-wrap li.tab a.active {
  color: rgb(51, 51, 51);
  background-color: #fff;
  border-bottom: none;
}

.tabs-wrap li.tab.help {
  float: right;
  border: none;
  transition: 0.3s;
}

.no-border {
  border: none;
}

.row-extend-right {
  margin-left: 0;
  margin-right: 0;
}
