body {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

/* @media screen and (max-width: 767px) {
  .sidebar-back {
    display: none;
  }
} */

.left-col {
  box-shadow: inset -2px 2px 3px #ddd;
}

.sidebarBack {
  width: 220px;
  padding-top: 20px;
  top: 0px;
  left: 0px;
  bottom: 0;
  position: fixed;
  box-shadow: inset -2px 2px 3px #ddd;
  background-position: center 95%;
  background-image: url(/src/client/images/aside-tree.jpg);
  background-color: rgb(247, 247, 247);
  /* background-repeat: no-repeat;
  background-size: 130px; */
}

.avatar:hover {
  background: #434a54;
}

.app-pane {
  background: #5d9cec;
  margin-left: 200px;
  padding-top: 25px;
}

.wrapper {
  padding-left: 200px;
}

.sidebarWrapper {
  /* margin-left: -200px; */
  /* left: 200px; */
  width: 200px;
  /* position: fixed; */
  height: 100%;
  overflow-y: auto;
  /* z-index: 1000; */
}

.pageContentWrapper {
  width: 100%;
  padding-top: 50px;
}

.sidebarNav {
  position: absolute;
  top: 0;
  width: 200px;
  list-style: none;
  margin: 0;
  padding: 0;
}

@media (max-width: 961px) {
  /* body {
      padding-top: 70px;
    }

    .wrapper {
      padding-left: 0;
    } */

  /* .sidebarWrapper {
      left: 0;
    }

    .wrapper.active {
      position: relative;
      left: 200px;
    }

    .wrapper.active .sidebarWrapper {
      left: 200px;
      width: 200px;
    }

    .sidebarBack {
      display: none;
    } */
}
