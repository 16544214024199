
.sidebar-nav {
    text-align: left;
    padding: 20px 0;
    margin: 0;
}

.sidebar-nav li {
    position: relative;
    line-height: 34px;
}

.sidebar-nav li a {
    display: block;
    padding: 0 20px 0 50px;
    text-decoration: none;
}

.sidebar-nav li.active {
    /* left: 100px; */
    background-color: #97ba52;
}

.sidebar-nav li.active a {
    color: #fff !important;
}

.sidebar-nav li a .fa {
    position: absolute;
    font-size: 20px;
    margin-top: 7px;
    margin-left: -30px;
}

.sidebar-nav .sidenav-dropdown {
    padding-left: 0px;
}

.sidebar-nav .sidenav-dropdown li {
  line-height: 24px;
}


/* @media screen and (max-width: 767px) {
  .sidebar-nav {
    display: none;
  }
} */
