.plan-container {
  display: flex;
}

.plan-summary {
  position: relative;
  /* width: 300px; */
  border: solid 3px transparent;
  border-radius: 3px;
  transition: filter 0.5s;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 470px;
  width: 100%;
  max-width: 375px;
}

.paper {
  border: 0px;
}

.plan-summary.selected {
  border: solid 3px #90b850;
  transition: filter 0.5s;
}

.plan-summary.selected > div:after {
  content: '';
  position: absolute;
  top: -10px;
  right: -10px;
  width: 32px;
  height: 32px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-1 -1 35 35' width='32' height='32' preserveAspectRatio='xMinYMin meet' %3E %3Ccircle cx='15.5' cy='15.5' r='14.5' fill='%23fff' stroke='%2390B850' stroke-width='3'/%3E %3Cpath fill='%2390B850' d='M11.826 19.983l-4.019-4.018a1.13 1.13 0 00-1.607 0 1.13 1.13 0 000 1.607l4.81 4.81a1.143 1.143 0 001.62 0L24.8 10.225a1.13 1.13 0 000-1.607 1.13 1.13 0 00-1.607 0L11.826 19.983z'/%3E %3C/svg%3E");
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.plan-summary.unselected {
  opacity: 0.9;
  filter: grayscale(70%);
  transition: filter 0.5s;
}

.plan-summary.unselected:hover {
  filter: grayscale(0%);
  transition: filter 0.5s;
}

.plan-header {
  background: #0068a8;
  min-height: 16px;
  padding: 8px;
  color: #fff;
  font-size: 18px;
  text-align: center;
  /* text-transform: uppercase; */
  /*   border-top-left-radius: 5px;
  border-top-right-radius: 5px; */
}

.plan-price {
  display: flex;
  background: #007ecc;
  color: #fff;
  text-align: center;
  padding: 12px 0;
  align-items: center;
  justify-content: center;
}

.price-container {
  display: flex;
  justify-content: center;
}

.price-container.sign {
  color: #dbf1ff;
  font-size: 24px;
  margin-right: 3px;
}

.price-container.amount {
  color: #dbf1ff;
  font-size: 80px;
  padding: 0;
  line-height: 0.8em;
}

.price-container.decimal {
  color: #dbf1ff;
  font-size: 24px;
}

.price-container.month {
  color: #dbf1ff;
  font-size: 1.4em;
  align-self: flex-end;
}

.price-container.extra {
  margin-top: 0.5em;
  font-size: 18px;
}

.price-desc {
  margin-top: 0.5em;
  font-size: 1.2em;
}

.plan-body {
  padding-top: 0.5em;
  font-size: 1.2em;
  background: #f5fbff;
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.security-fee {
  background: #fae082;
  padding: 1em;
  color: #ae8604;
  font-size: 0.8em;
  text-align: center;
}

ul.list {
  display: inline-block;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

ul.list > li {
  clear: left;
  margin-bottom: 18px;
  color: #555;
  font-size: 16px;
}

ul.list > li:before {
  content: '';
  height: 20px;
  width: 20px;
  display: block;
  float: left;
  margin-right: 16px;
  background-position: center;
  background-repeat: no-repeat;
}

.list.checkmark {
  transform: scale(0.9);
  height: auto;
}

.list.checkmark > li:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='28' height='28' preserveAspectRatio='xMinYMin meet'%3E %3Ccircle cx='15.5' cy='15.5' r='14.5' fill='%2390B850' stroke='%2390B850' stroke-width='2'/%3E %3Cpath fill='%23fff' d='M11.826 19.983l-4.019-4.018a1.13 1.13 0 00-1.607 0 1.13 1.13 0 000 1.607l4.81 4.81a1.143 1.143 0 001.62 0L24.8 10.225a1.13 1.13 0 000-1.607 1.13 1.13 0 00-1.607 0L11.826 19.983z'/%3E %3C/svg%3E");
  background-size: 100%;
}

.listHeader {
  clear: left;
  margin-bottom: 18px;
  color: #555;
  font-size: 16px;
}

.listHeader:before {
  content: '';
  height: 20px;
  width: 20px;
  display: block;
  float: left;
  margin-right: 16px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: none !important;
}
