.form-body {
  background: #fff;
  padding: 40px;
  border: solid 1px #ccc;
  margin-top: 50px;
}

.btn-submit {
  color: #97ba52;
  border: solid 2px #97ba52;
  padding: 12px 18px !important;
  transition: all 0.3s ease;
  margin-top: 20px;
}

.btn-submit:hover {
  color: #f4f8ed;
  background: #97ba52;
  transition: all 0.3s ease;
  cursor: pointer;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .form-body {
    background: #fff;
    padding: 24px;
    border: solid 1px #ccc;
    margin-top: 50px;
  }
}
