
.simple-loader,
.simple-loader:before,
.simple-loader:after {
  background: #006ab0;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  animation-fill-mode: both;
  animation: loader-animation 1.5s infinite ease-in-out;
}

.simple-loader {
  color: #006ab0;
  font-size: 10px;
  margin: 20px auto;
  position: relative;
  text-indent: -9999em;
  transform: scale(.8) translateZ(0);
  animation-delay: -0.10s;
}
.simple-loader:before,
.simple-loader:after {
  content: "";
  position: absolute;
  top: 0;
}
.simple-loader:before {
  left: -35px;
  animation-delay: -0.20s;
}
.simple-loader:after {
  left: 35px;
}

/* @keyframes load7 {
  0%,
  100% {
    transform: scale3d(3, 3, 3);
    filter: blur(0px);
  }
  45% {
    transform: scale3d(1, 1, 1);
    filter: blur(0px);
  }
} */

@keyframes loader-animation {
  0% {
    opacity: 0;
  }
  80%,
  100% {
    box-shadow: #97ba52 0 0 0 -0.70em;
    opacity: 1;
  }
  45% {
    box-shadow: 0 0 0 .6em;
    opacity: .80;
  }
}