.Accordion-wrapper {
  border-bottom: solid 1px #ddd;
  margin-bottom: 30px;
}

.AccordionPanel-header {
  position: relative;
  display: block;
  font-size: 110%;
  border-left: solid 1px #ddd;
  border-right: solid 1px #ddd;
  border-top: solid 1px #ddd;
  padding: 10px 10px 10px 28px;
  color: #000;
}

.AccordionPanel-header:hover {
  background: #f5f5f5;
  text-decoration: none;
}

.AccordionPanel-header::before {
  position: absolute;
  content: '';
  display: inline-block;
  margin-right: 5px;
  font-size: 110%;
  width: 0px;
  height: 0px;
  text-align: center;
  top: 15px;
  left: 10px;
  border-top: solid 5px transparent;
  border-left: solid 8px #000;
  border-bottom: solid 5px transparent;
}

.AccordionPanel-header-active {
  background: #f5f5f5;
  color: #006ab0;
}

.AccordionPanel-header-active::before {
  position: absolute;
  margin-right: 5px;
  font-size: 110%;
  width: 0px;
  height: 0px;
  border-left: solid 5px transparent;
  border-right: solid 5px transparent;
  border-top: solid 8px #006ab0;
  top: 17px;
}

.Accordion-header-inactive::before {
  color: #000;
}

.AccordionPanel-body {
  background: #f5f5f5;
  padding: 10px 10px 10px 10px;
  padding-right: 0;
  border-left: solid 1px #ddd;
  border-right: solid 1px #ddd;
}