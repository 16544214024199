.is-invalid {
    color: red;
}

.AutoSizerWrapper {
    flex: 1 1 auto;
  }
  
  .List {
    border: 1px solid #e0e0e0;
  }
  
  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 25px;
    background-color: #fff;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .checkboxLabel {
    display: flex;
    align-items: center;
  }
  .checkbox {
    margin-right: 5px;
  }