.root {
  position: relative;
  margin: 0;
  padding: 0;
}

.input {
  position: absolute;
  width: 100%;
  font-size: 16px;
  padding: 8px;
  border: solid 1px #000;
  outline: none;
  white-space: pre-wrap;
  z-index: 300;
  clear: both;
  box-sizing: border-box; /* Important */
}

.mirror {
  font-size: 16px;
  padding: 8px;
  border: solid 1px #000;
  outline: none;
  white-space: pre-wrap;
  z-index: 200;
}

.marker {
  position: absolute;
  background: purple;
  width: 10px;
  height: 10px;
  z-index: 400;
  border-radius: 50%;
}

.tracker {
  background: purple;
}

.tracker::after {
  content: " ";
}
