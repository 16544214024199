.question {
  margin: 35px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.question-text {
  font-size: 105%;
  float: left;
  max-width: 350px;
  padding-right: 16px;
}

.question-buttons {
  float: right;
  width: min-content;
}

.question-button {
  min-width: 75px;
  padding: 8px 18px;
  border: solid 1px transparent;
  transition: background 0.2s ease-out, color 0.1s ease-out;
}

.question-button:focus {
  outline: 2px dotted black;
}

.question-button:hover {
  padding: 8px 18px;
  color: #fff;
  background: #1a81c7;
  border: solid 1px transparent;
  transition: background 0.2s ease-out, color 0.1s ease-out;
}

.question-button--selected,
.question-button--selected:hover {
  color: #fff;
  background: #006ab0;
}

.clearfix {
  content: '';
  clear: both;
  display: table;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .question {
    margin: 8px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
