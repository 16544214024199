.main {
  position: relative;
  background: #fff;
  font-size: 85%;
  width: 320px;
  border: solid 1px #ccc;
  border-top: solid 5px #006ab0;
  box-shadow: 5px 5px 5px 1px #eee;
  box-sizing: border-box;
  position: absolute;
  top: 24px;
  z-index: 4000;
}

.calendar {
  background: #fff;
  position: relative;
  font-size: 85%;
  width: 320px;
  border: solid 1px #ccc;
  border-top: solid 5px #006ab0;
  box-shadow: 5px 5px 5px 1px #eee;
  box-sizing: border-box;
  position: absolute;
  /* top: 24px; */
  z-index: 4000;
  right: 0;
}

.footer {
  background: rgba(149, 208, 214, 0.2);
  display: flex;
  justify-content: flex-end;
  padding: 8px 8px;
  margin-top: 8px;
}

.switch {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.btn {
  background: transparent;
  border: none;
  padding: 8px;
  font-size: 14px;
  cursor: pointer;
}

.cta {
  background: #006ab0;
  border: solid 1px #006ab0;
  padding: 8px 20px;
  margin-left: 16px;
  color: #fff;
}
