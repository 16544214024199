.progress-bar-root {
  background-color: #f2f2f2;
  border-radius: 6px;
  padding: 10px 15px;
  margin-bottom: 20px;
}

.progress-bar-root .progress {
  margin-bottom: 10px;
}

.progress {
  background: rgba(151, 186, 82, 0.5);
}

.progress,
.progress-bar {
  border-radius: 10px;
  box-shadow: none;
  height: 14px;
  margin-bottom: 0;
}

.progress-bar {
  background-color: #97ba52;
}
