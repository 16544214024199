.axes path {
  stroke: lightgray;
  stroke-dasharray: '5 5';
  opacity: 0.5;
}

.axes .baseline {
  display: none;
}

.axes text {
  fill: white;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 12px;
}

.DividedLine {
  z-index: 10;
}

.frame text {
  fill: white;
}

.rect {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.tooltipContent {
  transform: translateX(-50%) translateY(5px);
  z-index: 999;
  position: absolute;
}

.wrapper {
  width: 400px;
  height: 400px;
  display: flex;
  align-items: center;
}

.content {
  width: 320px;
  height: 320px;
  -webkit-border-radius: 17px;
  -moz-border-radius: 17px;
  border-radius: 17px;
  border: #ffffff solid 12px;
  position: absolute;
  z-index: 9;
}
