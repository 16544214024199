.header {
  margin-top: 20px;
  width: 100%;
  border: dashed 2px #aeaeae;
  padding: 34px 20px;
  margin-bottom: 25px;
  color: #444447;
  border-radius: 4px;
  background-color: #fbfbfb;
  transition: opacity 0.3s;
  opacity: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  transition: all 0.2s;
}

.header.header--active,
.header:hover {
  border: dashed 2px #0068a8;
}

.header-gliphicon {
  width: 150px;
  height: 75px;
  object-fit: contain;
  margin-bottom: 16px;
  position: relative;
}

.header-gliphicon img {
  display: block;
  position: absolute;
  transition: all 0.3s;
}

.header:hover .dz-one,
.header.header--active .dz-one {
  transform: rotate(-8deg) translate(-6px, 2px);
}

.header:hover .dz-two,
.header.header--active .dz-two {
  transform: scale(1.05);
}

.header:hover .dz-three,
.header.header--active .dz-three {
  transform: rotate(8deg) translate(6px, 2px);
}

.dz-one {
  z-index: 1;
  width: 70px;
  left: calc(50% - 78px);
  transform: rotate(0deg) translate(0px, 0px);
}

.dz-two {
  z-index: 2;
  width: 50px;
  left: calc(50% - 25px);
  transform: scale(1);
}

.dz-three {
  z-index: 1;
  width: 70px;
  top: 2px;
  left: calc(50% + 9px);
  transform: rotate(0deg) translate(0px, 0px);
}

.header-text {
  opacity: 1;
  transition: all 0.3s;
}

.header.header--active .header-text,
.header:hover .header-text {
  opacity: 0.6;
}

.header-text strong {
  font-size: 21px;
  font-weight: 500;
  width: 100%;
  display: inline-block;
  text-align: center;
}

.header-text div {
  font-size: 15px;
  opacity: 0.9;
  text-align: center;
  margin-top: 4px;
  font-weight: 400;
}

.header-text span {
  color: #0068a8;
}
