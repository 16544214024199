.DashboardLinkButton {
  text-align: center;
  margin-bottom: 8px;
}

.DashboardLinkButton a {
  display: block;
  background-color: #f2f2f2;
  border: 1px solid transparent;
  border-radius: 6px;
  padding: 16px;
}

.DashboardLinkButton a:hover {
  border-color: #006ab0;
  text-decoration: none;
}

.DashboardLinkButton a span {
  display: block;
  margin-top: 8px;
}

.DashboardLinkButton img {
  width: 61px;
}

.DashboardLinkButton .fa {
  font-size: 48px;
}

.disabled-link {
  pointer-events: none;
  opacity: 0.5;
  color: #666;
  display: block;
  background-color: #f2f2f2;
  border: 1px solid transparent;
  border-radius: 6px;
  padding: 16px;
}
