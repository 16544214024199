.MyHealthRecordButton {
  text-align: center;
  margin-bottom: 10px;
  width: 160px;
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
}

.MyHealthRecordButton a {
  display: block;
  background-color: #f2f2f2;
  border: 1px solid transparent;
  border-radius: 6px;
  padding: 16px;
  height: 140px;
}

.MyHealthRecordButton a:hover {
  border-color: #006ab0;
  text-decoration: none;
}

.MyHealthRecordButton a span {
  display: block;
  font-size: 90%;
  margin-top: 5px;
}

.MyHealthRecordButton img {
  width: 50px;
}

.MyHealthRecordButton .fa {
  font-size: 48px;
}

@media (min-width: 992px) and (max-width: 1058px) {
  .MyHealthRecordButton a {
    height: 120px;
  }
}

@media (max-width: 404px) {
  .MyHealthRecordButton a {
    height: 130px;
  }
}
