
.rel-option {
  background: #f9f9f9;
  position: relative;
  color: #bbb;
  height: 58px;
  padding: 10px;
  margin-bottom: 10px;
  border: solid 1px #bbb;
  font-size: 90%;
}

.rel-option:after {
  content: "";
  background: #f2f2f2;
  position: absolute;
  top: 5px;
  right: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.normalize-pad {
  padding: 0px 0px;
  padding-right: 10px;
}

.no-gutter-left {
  margin-left: 0;
  padding-left: 0;
  margin-right: 0;
  padding-right: 0;
}

.rel-option--selected {
  background: #008ae6;
  color: #fff;
  border: solid 1px #fff;
  box-shadow: 4px 4px 4px #ddd;
}

.rel-option--selected:after {
  content: "";
  background: #fff;
  position: absolute;
  top: 5px;
  right: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.rel-option-button {
  background: #97ba52;
  color: #fff;
  border: solid 2px #97ba52;
}

.notes {
  width: 100%;
  padding: 10px;
  border: solid 1px #bbb;
  margin-bottom: 5px;
}

.notes::placeholder {
  color: #bbb;
}

.rel-selection {
  display: inline-block;
  background: #008ae6;
  color: #fff;
  font-size: 90%;
  margin-right: 5px;
  padding: 2px 8px;
  margin-top: 3px;
  border-radius: 4px;
  border: solid 1px #fff;
}