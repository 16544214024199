/* .container {
  margin-bottom: 18px;
} */

.container small {
  color: #006ab0;
  font-size: 120%;
}

.header {
  margin-top: 8px;
  margin-bottom: 8px;
}

.btn-back {
  color: #666;
  font-size: 95%;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
}

.btn-back:hover {
  color: #006ab0;
}
