/**
.SingleDatePickerInput {
    background-color: #fff;
    border: 1px solid #dbdbdb;

    
    display: block;
    width: 100%;
    height: 34px;
    
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

.DateInput {
  background: #000;
  font-weight: normal;
  font-size: inherit;
  color: #000;
  margin: 0;
  padding: 0px;
  background: #fff;
  position: relative;
  display: inline-block;
  width: 130px;
  vertical-align: middle;
  border-radius: 50%;
}


.SingleDatePicker__picker {
  z-index: 1;
  background-color: #fff;
  position: absolute;
  top: 52px;
}

.DateInput--with-caret::after {
  top: 43px;
  border-bottom-color: #fff;
}

.DateInput--with-caret::before {
  top: 42px;
  border-bottom-color: rgba(0, 0, 0, .1);
}

*/

.form-header {
  margin-bottom: 15px;
}

.form-header h3 {
  margin-bottom: 5px;
}

.form-header span {
  color: #006ab0;
  font-size: 120%;
}

.CalendarDay__highlighted_calendar {
  background: #0875e0 !important;
  color: #186a3b;
}

.CalendarDay__highlighted_calendar:hover {
  background: #0875e0 !important;
  color: #186a3b;
}

.CalendarDay__highlighted_calendar:active {
  background: #0875e0 !important;
  color: #186a3b;
}

.Select-control {
  padding-bottom: 4px;
}

.Select--multi .Select-value {
  color: #000;
}

.Select--multi .Select-value-label {
  padding: 4px 8px;
}

.Select-placeholder,
.Select--single > .Select-control .Select-value {
  line-height: 38px;
}
